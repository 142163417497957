<template>

  <div class="layout-agreement">

  </div>

</template>

<script>
export default {
  name: 'layout-agreement',
  components: {},
  props: {},
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss">

</style>
